import React, { useState } from 'react';
import './brand.css'; 
import CTA from '../../components/cta/CTA';
import oskar_image from '../../assets/Oskar_profile.jpg';
import vilhelm_image from '../../assets/Vilhelm_profile.jpg';

const Brand = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="cta-container">
      <div className="people_presentation">
        <h1>Vi som ligger bakom VIOS</h1>
        <div className='person_person1'>
          <img src={oskar_image} alt="Oskar" className='round_picture_pres'/>
          <p className='presentation_description'>Som bokföringsexperten i vårt team är Oskar den som håller ordning på siffrorna och ser till att allt stämmer i bakgrunden.
          Med en passion för precision och en kärlek till Excel är Oskar vår go-to-person när det gäller att hantera allt från fakturor till budgetar.
          Med Oskar vid din sida kan du vara säker på att din ekonomi är i trygga händer.</p>
        </div>
        <div className='person_person2'>
          <p className='presentation_description'>Vilhelm är hjärnan bakom strategin och den drivande kraften när det gäller att ta företag till nya höjder. Som erfaren entreprenör vet Vilhelm 
          vad som krävs för att bygga och driva framgångsrika företag. Med hans expertis och engagemang är han alltid redo att guida våra kunder genom 
          affärsutmaningarna och hjälpa dem att nå sina mål.</p>
          <img src={vilhelm_image} alt="Vilhelm" className='round_picture_pres'/>
        </div>
        <div className='cta-people-pres'>
          <h2 className='cta-people-pres-title'>Kontakta oss idag för att ta reda på hur vi kan hjälpa dig att uppnå dina mål och ta din verksamhet till nästa nivå. Vi ser fram emot att bli din partner!</h2>
          <button className="cta-button" onClick={handleOpenPopup}>Kontakta oss</button>
          {showPopup && <CTA onClose={handleClosePopup} />}
        </div>
      </div>
    </div>
  );
}

export default Brand
