import React, { useState } from 'react';
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";

import './features.css';

const Subject = ({ title, description, checklist }) => {
  const [showDescription, setShowDescription] = useState(false);

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return (
    <div className="subject-frame">
      <div className="subject-header" onClick={toggleDescription}>
        {title}
        <span className="toggle-icon">{showDescription ? <BiSolidUpArrow /> : <BiSolidDownArrow />}</span>
      </div>
      {showDescription && (
        <div className="subject-description">
          {/* Render description with line breaks */}
          {description.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
          <br />
          {/* Render checklist items with checkmark icons */}
          {checklist.length > 0 && (
            <div className="checklist">
              {checklist.map((item, index) => (
                <div key={index} className="checklist-item">
                  <FaCheck className="checkmark-icon" /> {item}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const Features = () => {
  return (
    <div className='VIOS__products' id='erbjudande'>
      <div className="products-title">Vi hjälper dig med</div>
      <Subject
        title="Redovisning"
        description={
          "Effektiv redovisning är avgörande för framgångsrikt företagande. På VIOS erbjuder vi en omfattande redovisningstjänst " + 
          "som sträcker sig från noggrann bokföring till korrekt deklaration. Vårt mål är att underlätta den administrativa bördan " +
          "för ditt företag, så att du kan fokusera på din kärnverksamhet."
        }
        checklist={[
          "Löpande bokföring av fakturor och kvitton",
          "Momsredovisning",
          "Bokslut",
          "Lönehantering"
        ]}
      />
      <Subject
        title="Rådgivning"
        description={
          `På VIOS förstår vi att varje företag är unikt, vilket är varför vår rådgivning är skräddarsydd efter dina specifika affärsmål och behov. Vi strävar efter att bli din långsiktiga partner och arbetar nära dig för att identifiera affärsmöjligheter och utmaningar. 

          För att vi vill se ditt bolag lyckas erbjuder vi löpande uppföljningsmöten och agerar som ett bollplank där vi ger dig verktygen för att lyckas. För oss är du inte bara ett kundnummer utan en värdefull partner.`
        }
        checklist={[
        ]}
      />
    </div>
  );
};

export default Features;
