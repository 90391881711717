import React from 'react';
import './whatVIOS.css';
import image from '../../assets/oskar_om_oss.jpeg';

const WhatVIOS = () => {
  return (
    <div className='om__VIOS' id='omvios'>
      <div className='om__left-column'>
        <span className='bar'></span>
        <h1 className='om_VIOS_titel'>Om oss</h1>
        <p className='om__VIOS__text'>
        Välkommen till VIOS – din pålitliga partner i redovisning och affärsrådgivning! 
        Vi är mer än bara en redovisningsbyrå. Vi är resurs åt er mindre och mellanstora 
        företag som strävar efter att växa och blomstra i en konkurrenskraftig affärsvärld. 
        Hos oss är du inte bara ett kundnummer, utan en värdefull partner. Vi tror på att 
        skapa långsiktiga relationer och erbjuda den personliga uppmärksamhet som du och 
        ditt företag förtjänar. <br/><br/>
 
        För många mindre företag är det ekonomiskt utmanande att ha en dedikerad intern ekonomiavdelning. 
        Det är här vi kommer in i bilden. Vi tillhandahåller inte bara de nödvändiga redovisningstjänsterna, 
        utan fungerar även som din externa ekonomiavdelning och affärsrådgivare. Vi arbetar hand i hand med 
        dig för att förstå din verksamhet, identifiera möjligheter och utmaningar, och utveckla skräddarsydda 
        lösningar som driver ditt företag framåt. <br/><br/>
 
        Oavsett om du behöver hjälp med bokföring, skatterådgivning eller affärsstrategi, är vi här för att 
        stötta dig. Tillsammans kan vi skapa en stark grund för din framgång och tillväxt.
        </p>
      </div>
      <div className='om__right-column'>
        <img src={image} alt="Oskar" />
      </div>
    </div>
  )
}

export default WhatVIOS