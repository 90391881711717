import React from 'react';
import './header.css';

const Header = () => {

  return (
    <div className='VIOS__header__section__padding'>
      <div className='VIOS__header__content'>
        <h1 className='header__text'>Vi löser din redovisning</h1>
        <p className='header__sub__text' >Så att du kan fokusera på din kärnverksamhet</p>
      </div>
    </div>
  )
}

export default Header