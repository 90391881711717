import React from 'react';

import { Footer, Possibility, Features, WhatVIOS, Header } from './containers';
import { Brand, Navbar } from './components';
import headerImage from './assets/header_3.jpg';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <div className="picture__bg" style={{backgroundImage: `url(${headerImage})`}}>
        <Navbar />
        <Header />
      </div>
      <WhatVIOS />
      <Features />
      <Possibility />
      <Brand />
      <Footer />
    </div>
  )
}

export default App
