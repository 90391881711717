import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/VIOS_WHITE-01.svg';
import CTA from '../../components/cta/CTA';
import './navbar.css';

const Menu = ({ closeMenu }) => (
  <>
    <p><a href='#home' onClick={closeMenu} className='menu-items'>Hem</a></p>
    <p><a href='#omvios' onClick={closeMenu} className='menu-items'>Om</a></p>
    <p><a href='#erbjudande' onClick={closeMenu} className='menu-items'>Erbjudande</a></p>
    <p><a href='#prisuppskattning' onClick={closeMenu} className='menu-items'>Pris</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleMenuToggle = () => {
    setToggleMenu(prevState => !prevState);
  };

  const closeMenu = () => {
    setToggleMenu(false);
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className='VIOS__navbar' id='home'>
      <div className='VIOS__navbar__links'>
        <div className='VIOS__navbar__logo'>
          <a href="#home"><img src={logo} alt='logo' /></a>
        </div>
        <div className='VIOS__navbar__links__container'>
          <Menu closeMenu={closeMenu} />
        </div>
      </div>
      <div className="VIOS__navbar__contact">
        <button className="contact-button" onClick={handleOpenPopup}>Kontakta oss</button>
      </div>
      <div className='VIOS__navbar__menu'>
        {toggleMenu
          ? <RiCloseLine color='#fff' size={27} onClick={handleMenuToggle} />
          : <RiMenu3Line color='#fff' size={27} onClick={handleMenuToggle} />
        }
        {toggleMenu && (
          <div className='VIOS__navbar__menu__container scale-up-center'>
            <div className='VIOS__navbar__menu__container__links'>
              <Menu closeMenu={closeMenu} />
            </div>
            <div className="VIOS__navbar__menu__container__links__contact">
              <button className="contact-button" onClick={handleOpenPopup}>Kontakta oss</button>
            </div>
          </div>
        )}
      </div>
      {showPopup && <CTA onClose={handleClosePopup} />}
    </div>
  )
}

export default Navbar;