import React, { useState} from 'react';
import { TfiClose } from "react-icons/tfi";
import { BsEnvelopeCheck } from "react-icons/bs";
import './cta.css';

function CTA({ onClose, priceConfirmation }) {

  const [formSubmitted, setFormSubmitted] = useState(false);

  const autoExpand = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = (textarea.scrollHeight + 2) + 'px';
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "3a7a0725-822b-4dbb-8807-dd63c3782242");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
      setFormSubmitted(true);
    }
  };

  return (
    <div className='__contact-form' id='kontakt'>
      <div className='contact-form-popup'>
        <div className='background-header'>
          <button className='close-button' onClick={onClose}>
            <TfiClose />
          </button>
          {(formSubmitted || priceConfirmation) ? (
            <div className='header_texts'>
              <h2>Tack för att du kontaktade oss!</h2>
              <BsEnvelopeCheck className="check_icon" />
            </div>
          ) : (
            <div className='header_texts'>
              <h2>Hör av dig till oss!</h2>
              <p>Fyll i formuläret så kontaktar vi dig så snart som möjligt.</p>
          </div>
        )}
        </div>
        <div className='background-form'>
        {(formSubmitted || priceConfirmation) ? (
          <div>
            <p>Vi återkommer till er inom 2 arbetsdagar.</p>
          </div>
        ) : (
          <div>
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <input type="text" id="name" name="name" placeholder="Namn" required />
              </div>
              <div className="form-group">
                <input type="tel" id="phone" name="phone" placeholder="Telefonnummer" required />
              </div>
              <div className="form-group">
                <input type="email" id="email" name="email" placeholder="E-post" required />
              </div>
              <div className="form-group">
                <input type="text" id="company" name="company" placeholder="Företag" />
              </div>
              <div className="form-group">
                <textarea id="message" name="message" rows="4" placeholder="Vad kan vi hjälpa dig med?" required onInput={(e) => autoExpand(e.target)}></textarea>
              </div>
              <button type="submit" className="cta-button-send">Boka möte</button>
            </form>
            <p className="privacy-text">Vi värnar om din personliga integritet. Genom att klicka "boka möte" godkänner du våra allmänna villkor.</p>
          </div>
        )}
        </div>
      </div>
    </div>
  );
}

export default CTA
