import React, { useState } from 'react';
import { GiReceiveMoney } from "react-icons/gi";
import { FaFileInvoiceDollar, FaReceipt } from "react-icons/fa6";
import './possibility.css';
import CTA from '../../components/cta/CTA';

const Possibility = () => {

  // State variables to keep track of slider values
  const [monthlySalary, setMonthlySalary] = useState(0);
  const [monthlyInvoices, setMonthlyInvoices] = useState(0);
  const [monthlyReceipts, setMonthlyReceipts] = useState(0);
  const [annualTurnover, setAnnualTurnover] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: ''
  });
  const [showPopup, setShowPopup] = useState(false);

  // Function to handle slider change
  const handleSliderChange = (value, setValue) => {
    setValue(value);
  };

  // Function to handle input change for annual turnover
  const handleTurnoverInputChange = (e) => {
    const inputValue = e.target.value;
    let newValue = parseInt(inputValue.replace(/\D/g, ''));

  // Ensure the input value doesn't exceed the maximum slider value
  if (newValue > 10000000) {
    newValue = 10000000;
  }
    setAnnualTurnover(newValue);
  };

  function addThousandSeparators(input) {
    // Remove non-digit characters and leading zeros
    const numberString = input.toString().replace(/\D|^0+/g, '');

    // Format with spaces
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  // Function to handle input change for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Function to handle button click
  const handleCalculatePrice = async () => {
    // Check if any input field is empty
    if (!formData.name || !formData.email || !formData.phone || !formData.company) {
      alert('Fyll i alla obligatoriska(*) fält');
      return; // Stop execution if any field is empty
    }
  
    const data = {
      ...formData,
      monthlySalary,
      monthlyInvoices,
      monthlyReceipts,
      annualTurnover
    };
  
    // Track form submission with Meta Pixel including the test event code
    window.fbq('track', 'Lead', {
      content_name: 'Quotation Form',
      content_category: 'Form Submission',
      value: annualTurnover, // This is a numeric value you want to associate with the event
      currency: 'SEK',
      contact_info: {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        company: formData.company
      },
      sliders: {
        monthlySalary,
        monthlyInvoices,
        monthlyReceipts
      },
      test_event_code: 'TEST63994' // Add the test event code here
    });
  
    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        access_key: "3a7a0725-822b-4dbb-8807-dd63c3782242",
        ...data
      })
    }).then((res) => res.json());
  
    if (res.success) {
      console.log("Success", res);
      // Reset form data and sliders
      setFormData({
        name: '',
        email: '',
        phone: '',
        company: ''
      });
      setMonthlySalary(0);
      setMonthlyInvoices(0);
      setMonthlyReceipts(0);
      setAnnualTurnover(0);
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className='VIOS__price__prop' id='prisuppskattning'>
      {/* Header */}
      <h2>Fyll i uppgifterna för att få en kostnadsfri offert</h2>
      <span className='bar-center'></span>

      {/* Sliders */}
      <div className="slider-container">
        <div className="slider-item">
          <div className="prop-circle"></div> {/* Circular shape */} 
          <div className='slider-contents'>
            <GiReceiveMoney className='prop-icon' />
            <h2 className='prop-number'>{monthlySalary}{monthlySalary === 25 ? '+' : ''}</h2>
          </div>
          <p>Löner per månad</p>
          <input className='VIOS-slider' type="range" min="0" max="25" step="1" value={monthlySalary} onChange={(e) => handleSliderChange(parseInt(e.target.value), setMonthlySalary)} />
        </div>
        <div className="slider-item">
          <div className="prop-circle"></div> {/* Circular shape */}
          <div className='slider-contents'>
            <FaFileInvoiceDollar className='prop-icon' />
            <h2 className='prop-number'>{monthlyInvoices}{monthlyInvoices === 100 ? '+' : ''}</h2>
          </div> 
          <p>Fakturor per månad</p>
          <input className='VIOS-slider' type="range" min="0" max="100" step="5" value={monthlyInvoices} onChange={(e) => handleSliderChange(parseInt(e.target.value), setMonthlyInvoices)} />
        </div>
        <div className="slider-item">
          <div className="prop-circle"></div> {/* Circular shape */}
          <div className='slider-contents'>
            <FaReceipt className='prop-icon' />
            <h2 className='prop-number'>{monthlyReceipts}{monthlyReceipts === 100 ? '+' : ''}</h2>
          </div> 
          <p>Kvitton per månad</p>
          <input className='VIOS-slider' type="range" min="0" max="100" step="5" value={monthlyReceipts} onChange={(e) => handleSliderChange(parseInt(e.target.value), setMonthlyReceipts)} />
        </div>
      </div>

      {/* Annual income */}
      <div className="VIOS-annual-revenue">
        <h2>Årlig omsättning (kr):</h2>
        <div className="annual-revenue">
          <input id="annualTurnoverSlider" className='VIOS-slider' type="range" min="0" max="10000000" step="500000" value={annualTurnover} onChange={(e) => handleSliderChange(parseInt(e.target.value), setAnnualTurnover)} />
          <div className='container-input-revenue'>
            <input className='input-revenue' type="text" value={addThousandSeparators(annualTurnover) + (annualTurnover === 10000000 ? '+' : '')} onChange={handleTurnoverInputChange} />
            <p>kr</p>
          </div>
        </div>
      </div>

      <div className='VIOS-offer-form'>
        <h2>Kontaktuppgifter</h2>
        <div className="form-group-price">
          <label htmlFor="name">Namn*</label>
          <input type="text" id="name" name="name" placeholder='Namn' value={formData.name} onChange={handleInputChange}/>
        </div>
        <div className="form-group-price">
          <label htmlFor="email">E-post*</label>
          <input type="email" id="email" name="email" placeholder='E-post' value={formData.email} onChange={handleInputChange} />
        </div>
        <div className="form-group-price">
          <label htmlFor="phone">Telefonnummer*</label>
          <input type="tel" id="phone" name="phone" placeholder='Telefonnummer' value={formData.phone} onChange={handleInputChange} />
        </div>
        <div className="form-group-price">
          <label htmlFor="company">Företagsnamn*</label>
          <input type="text" id="company" name="company" placeholder='Företagsnamn' value={formData.company} onChange={handleInputChange} />
        </div>
        <button className="cta-button-offert" onClick={handleCalculatePrice}>Få offert</button>
      </div>
      
      {showPopup && <CTA onClose={handleClosePopup} priceConfirmation={true} />}

    </div>
  )
}

export default Possibility