import React from 'react';
import logo from '../../assets/VIOS_LOGO_WHITE_HORIZ-01.svg';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import './footer.css';

const Footer = () => {
  return (
    <div className='VIOS__footer'>
      <div className='footer__column'>
        <div className='VIOS__footer__logo'>
          <img src={logo} alt='logo' />
        </div>
        <p className='footer__text'>Hos oss är du mer än ett kundnummer!</p>
      </div>
      <div className='footer__column'>
        <h1 className='footer__title'>Kontakt</h1>
        <span className='bar white'></span>
        <p className='footer__text'> Tel: 072441666</p>
        <p className='footer__text'> oskar.notelid@vios.se</p>
        <div className='social-media'>
          <a href='https://www.facebook.com/profile.php?id=61556024915512' target='_blank' rel='noopener noreferrer' className='social-media-link'><FaFacebook /></a>
          <a href='#home' className='social-media-link'><FaLinkedin /></a>
          <a href='https://www.instagram.com/viosredovisning/' target='_blank' rel='noopener noreferrer' className='social-media-link'><FaInstagram /></a>
        </div>
      </div>
      <p className='footer__text__copyright'> Made by Notelid for VIOS AB © 2024. All rights reserved.</p>
    </div>
  )
}

export default Footer